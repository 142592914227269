"use client";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import apyModule from "../../logic/ApyModule";
import { useErrorAlert } from "logic/hooks";
import { handleErrors } from "errors";
import currenciesModule from "logic/CurrenciesModule";

export type CurrencyApyData = {
  currencyName: string;
  depositApy: number;
  borrowApy: number;
};

type IApyContext = CurrencyApyData[];

export const ApyContext = createContext<IApyContext>([]);

export default function ApyProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const [apys, setApys] = useState<CurrencyApyData[]>([]);
  const [error, setError] = useState<Error | null>(null);
  useErrorAlert(error);

  useEffect(() => {
    handleGetApys();
  }, []);

  const handleGetApys = async () => {
    try {
      const apys = await Promise.all(
        currenciesModule.erc20Currencies.map((currency) =>
          apyModule.getApys(currency)
        )
      );

      setApys(apys);
    } catch (err) {
      setError(handleErrors(err));
    }
  };

  return (
    <>
      <ApyContext.Provider value={apys}>{children}</ApyContext.Provider>
    </>
  );
}

export const useApy = (currencyName: string) => {
  const apys = useContext(ApyContext);

  const apy = apys.find((_apy) => _apy.currencyName === currencyName);

  return { borrowApy: apy?.borrowApy, depositApy: apy?.depositApy };
};
