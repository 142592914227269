"use client";

import React, { type PropsWithChildren, useEffect, useState } from "react";
import logic from "../../logic";
import { LockeysHolderType } from "../../logic/types"
import { useToggle } from "react-use";
import { ModalConnectWallets, ModalSign, ModalSwitchNetwork } from "../modals";
import { ConnectionError } from "../../errors";
import { useCustomWallet } from "./WalletProvider";
import { GeneralContext } from "./contexts";
import useAlertNotification from "../hooks/useAlertNotification";

declare global {
  interface Window {
    hashmail: any;
  }
}

export default function GlobalProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const { address } = useCustomWallet();
  const [error, setError] = useState<Error | null>(null);
  const [, openAlertNotification] = useAlertNotification();

  // Modals state
  const [isModalConnectWalletsOpen, toggleModalConnectWallets] =
    useToggle(false);
  const [isModalSignOpen, toggleModalSign] = useToggle(false);
  const [isModalSwitchNetworkOpen, toggleModalSwitchNetwork] = useToggle(false);

  // lockeysHolderType
  const [lockeysHolderType, setLockeysHolderType] =
    useState<LockeysHolderType | null>(null);

  useEffect(() => {
    if (error) {
      openAlertNotification(
        "error",
        "Web application not available. Please try again later",
        5000
      );
    }
  }, [error]);

  useEffect(() => {
    let counterCall = 0;
    if (address) {
      (async function call() {
        try {
          const lockeysHolderTypeRetrieved = await logic.retrieveLockeysHolderType(
            address
          );

          setLockeysHolderType(lockeysHolderTypeRetrieved);
        } catch (err) {
          counterCall++;
          if (counterCall < 3) {
            call();
          } else {
            setError(new ConnectionError("Unexpected error. Please try again"));
          }
        }
      })();
    }
  }, [address]);

  return (
    <>
      <GeneralContext.Provider
        value={{
          modalConnectWallets: [
            isModalConnectWalletsOpen,
            toggleModalConnectWallets,
          ],
          modalSign: [isModalSignOpen, toggleModalSign],
          modalSwitchNetwork: [
            isModalSwitchNetworkOpen,
            toggleModalSwitchNetwork,
          ],
          lockeysHolderType,
        }}
      >
        {children}
      </GeneralContext.Provider>
      <ModalConnectWallets
        isOpen={isModalConnectWalletsOpen}
        toggleModal={toggleModalConnectWallets}
      />
      <ModalSign isOpen={isModalSignOpen} toggleModal={toggleModalSign} />
      <ModalSwitchNetwork
        isOpen={isModalSwitchNetworkOpen}
        toggleModal={toggleModalSwitchNetwork}
      />
    </>
  );
}
