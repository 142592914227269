"use client";
import classNames from "classnames";
import { type FC, useState } from "react";
import { useUpdateEffect } from "react-use";
import { BigIntInput } from "./BigIntInput";
import TokenIcon from "components/icons/TokenIcon";
import { ICurrency } from "logic/types/currency/ICurrency";
import currenciesModule from "logic/CurrenciesModule";

const DECIMALS_DEFAULT_VALUE = 5;
const DIGITS_DEFAULT_VALUE = 18;

interface Props {
  className?: string;
  nameInput: string;
  label?: string;
  max?: bigint;
  onChange?: (amount: bigint) => void;
  onMax?: () => void;
  balance?: bigint;
  defaultValue?: bigint;
  decimals?: number;
  maxButtonHidden?: boolean;
  required?: boolean;
  value?: bigint;
  readOnly?: boolean;
  dataField?: React.ReactNode;
  isNegativeAllowed?: boolean;
  digits?: number;
  iconHidden?: boolean;
  placeholder?: string;
  currency?: ICurrency;
  middleDataField?: React.ReactNode;
}

const Input: FC<Props> = (props) => {
  const {
    className,
    balance,
    nameInput,
    max,
    digits: _digits,
    onChange,
    defaultValue,
    decimals: _decimals,
    onMax = () => null,
    maxButtonHidden,
    label = "Amount",
    value,
    iconHidden = false,
    readOnly = false,
    isNegativeAllowed = false,
    dataField,
    placeholder,
    currency,
    middleDataField,
    ...restProps
  } = props;
  const digits = _digits || currency?.decimals || DIGITS_DEFAULT_VALUE;
  const decimals =
    _decimals || currency?.decimalsToShow || DECIMALS_DEFAULT_VALUE;

  const currencyLabel = currency?.label || "ETH";

  const [weiValue, setWeiValue] = useState<bigint>(
    defaultValue !== undefined ? BigInt(defaultValue) : BigInt(0)
  );

  useUpdateEffect(() => {
    onChange?.(weiValue);
  }, [weiValue]);

  useUpdateEffect(() => {
    if (value !== undefined && value !== weiValue) {
      setWeiValue(value);
    }
  }, [value]);

  const handleChange = (value: string) => {
    const weiVal = value ? BigInt(value) : BigInt(0);
    setWeiValue(weiVal);
  };

  const handleMaxButton = () => {
    if (max) {
      setWeiValue(max);
    }
    onMax();
  };

  return (
    <>
      <label
        className={classNames(
          "border-2 rounded-xl transition-colors duration-100 ease-in-out px-4 py-3 grid grid-cols-[repeat(5,1fr)_86px] grid-rows-[repeat(2,27px)] gap-2",
          className
        )}
        htmlFor={nameInput}
      >
        <div
          className={classNames(
            "row-[1_/_span_2] flex flex-col justify-between",
            iconHidden ? "col-[1_/_span_6]" : "col-[1_/_span_5]"
          )}
        >
          <span
            className={classNames("text-xs xs:text-md block max-3xs:hidden")}
          >
            {label}
          </span>
          <BigIntInput
            decimals={decimals}
            name={nameInput}
            digits={digits}
            className={classNames(
              "bg-transparent w-full h-[27px] !outline-none text-2xl xs:text-3xl font-bold",
              readOnly ? "text-[#9A9A9A]" : "text-white"
            )}
            onChange={handleChange}
            value={weiValue.toString()}
            readOnly={readOnly}
            isNegativeAllowed={isNegativeAllowed}
            placeholder={placeholder}
            {...restProps}
          />
        </div>

        {!iconHidden && (
          <div className="col-start-6 row-start-1 w-full h-full flex justify-center items-center">
            <div className="w-full h-full flex border border-white rounded-full items-center">
              <TokenIcon
                currency={currency || currenciesModule.nativeCurrency}
                className="w-[26px] h-[26px]"
              />
              <span className="ml-2 text-sm">{currencyLabel}</span>
            </div>
          </div>
        )}
        {!maxButtonHidden && (
          <div className="col-start-6 row-start-2 w-full h-full flex justify-center items-center">
            <button
              onClick={handleMaxButton}
              className="primary-button w-full h-full text-xs"
              type="button"
              disabled={!max || readOnly}
            >
              MAX
            </button>
          </div>
        )}
        {dataField !== undefined && (
          <div className="col-start-6 row-start-2 w-full h-full">
            {dataField}
          </div>
        )}
        {middleDataField && (
          <div className="col-start-3 col-span-2 row-start-1 row-span-1 w-max h-full">
            {middleDataField}
          </div>
        )}
      </label>
    </>
  );
};

export default Input;
