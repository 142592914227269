"use client";
import { PropsWithChildren, useEffect, useState } from "react";
import { useCustomWallet } from "./providers/WalletProvider";
import Script from "next/script";

export default function HashmailScript({
  children,
}: PropsWithChildren): JSX.Element {
  const [isHashmailLoaded, setIsHashmailLoaded] = useState<boolean>(false);

  const { address } = useCustomWallet();

  useEffect(() => {
    if (address && isHashmailLoaded) window.hashmail.identify(address);
  }, [address, isHashmailLoaded]);

  return (
    <>
      {children}
      <Script
        src="/static/hashmail.js"
        onLoad={() => {
          setIsHashmailLoaded(true);
        }}
      />
    </>
  );
}
