import { OptionsWriteMethod } from "@unlockdfinance/verislabs-web3";
import { wethContract } from "../contracts";

class WethModule {
  wrapEth(amount: bigint, options?: OptionsWriteMethod) {
    return wethContract.deposit(amount, options);
  }

  unwrapEth(amount: bigint, options?: OptionsWriteMethod) {
    return wethContract.withdraw(amount, options);
  }
}

export default new WethModule();
