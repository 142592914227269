"use client";
import { type FC } from "react";
import { Anchor } from "../..";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { BsBoxArrowInLeft } from "react-icons/bs";
import { IoMdCopy } from "react-icons/io";
import { useCopyToClipboard } from "react-use";
import classNames from "classnames";
import { Divider } from "../../header/Divider";
import { useCustomWallet } from "../../providers";
import { BasicProps } from "../../types/BasicProps";
import RoundedIconEthereum from "../../icons/RoundedIconEthereum";
import ModalWeth from "../../modals/modal-weth/ModalWeth";
import useModalHelper from "../../modals/hooks/useModalHelper";
import { externalWalletModule } from "../../../clients/verisModule";
import { app } from "app.config";

type Props = BasicProps & {
  isOpen: boolean;
  togglePanel: (nextValue?: boolean) => void;
};

const IS_WRAPPER_ENABLED = app.FUNCTIONALITIES["eth-wrapper"];

export const PanelWallet: FC<Props> = (props) => {
  const { togglePanel } = props;
  const { address, chainId: chain } = useCustomWallet();
  const [, copyToClipboard] = useCopyToClipboard();
  const [isOpen, toggleModal, launcherModal] = useModalHelper();

  const copyAddressToClipboard = () => copyToClipboard(address!);

  const isNetworkSupported = chain === app.CHAIN.id;

  const chainName = app.CHAIN.name || "unknown chain";

  const handleOpenWrapModalClick = () => {
    launcherModal();
  };

  const handleDisconnect = () => {
    externalWalletModule.disconnect();
  };

  return (
    <>
      <div
        className="fixed top-0 right-0 w-screen h-screen"
        onClick={() => {
          togglePanel(false);
        }}
      ></div>
      <ul
        className="bg-secondary w-[180px] top-1 -left-8 absolute z-30 translate-y-7
      rounded-lg border border-white border-glow-sm overflow-hidden"
      >
        <li className="bg-secondary px-3 py-4">
          <span className=" ml-1.5 mb-2 block text-2xs md:text-xs text-white font-normal">
            Network
          </span>
          <div
            className="flex items-center text-sm md:text-md
            text-white font-bold bg-secondary w-full"
          >
            <div className="relative w-6 h-6 mr-2 flex items-center justify-center">
              <div
                className={classNames(
                  "w-3 h-3 rounded-full transition-colors duration-300",
                  {
                    "bg-green-400": isNetworkSupported,
                    "bg-red-600": !isNetworkSupported,
                  }
                )}
              ></div>
            </div>
            <span>{chainName}</span>
          </div>
        </li>
        <Divider className="mx-auto w-52" />
        <li className="flex items-center bg-secondary">
          <button
            onClick={() => copyAddressToClipboard()}
            className="flex items-center text-sm md:text-md px-3 py-2 mt-2 text-white font-normal w-full
          transition-colors duration-300 hover:bg-slate-700 focus-visible:bg-slate-700 active:bg-slate-600"
          >
            <div className="relative w-5 h-5 mr-2 flex items-center justify-center">
              <IoMdCopy className="w-full h-full -translate-x-0.5" />
            </div>
            <span>Copy Address</span>
          </button>
        </li>
        <li className="flex items-center bg-secondary">
          <Anchor
            href={`https://${
              app.CHAIN.name === "goerli" || app.CHAIN.name === "sepolia"
                ? app.CHAIN.name + "."
                : ""
            }etherscan.io/address/${address}`}
            target="_blank"
            title="View on explorer"
            rel="noopener noreferrer"
            className="flex items-center text-sm md:text-md px-3 py-2 text-white font-normal w-full
          cursor-pointer transition-colors duration-300 hover:bg-slate-700 focus-visible:bg-slate-700 active:bg-slate-600"
          >
            <div className="relative w-5 h-5 mr-2 flex items-center justify-center">
              <BsBoxArrowUpRight className="w-full h-full pr-0.5" />
            </div>
            <span>View on explorer</span>
          </Anchor>
        </li>
        {IS_WRAPPER_ENABLED && (
          <li className="flex items-center bg-secondary">
            <button
              className="flex items-center text-sm md:text-md px-3 py-2 mb-2 text-white font-normal w-full
            transition-colors duration-300 hover:bg-slate-700 focus-visible:bg-slate-700 active:bg-slate-600"
              onClick={handleOpenWrapModalClick}
            >
              <div className="relative w-[18px] h-[18px] mr-2.5 flex items-center justify-center">
                <RoundedIconEthereum className="w-[18px] h-[18px]" />
              </div>
              <span>Wrap ETH</span>
            </button>
          </li>
        )}
        <li className="flex items-center bg-secondary">
          <button
            className="flex items-center text-sm md:text-md px-3 py-2 mb-2 text-white font-normal w-full
            transition-colors duration-300 hover:bg-slate-700 focus-visible:bg-slate-700 active:bg-slate-600"
            onClick={handleDisconnect}
          >
            <div className="relative w-5 h-5 mr-2 flex items-center justify-center">
              <BsBoxArrowInLeft className="w-full h-full" />
            </div>
            <span>Disconnect wallet</span>
          </button>
        </li>
      </ul>

      <ModalWeth isOpen={isOpen} toggleModal={toggleModal} />
    </>
  );
};
